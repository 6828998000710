<template>
  <DelayHydration>
    <UiCheckoutPageHeader
      v-if="props.initialData"
      :base-url="localePath()"
      class="h-0"
      @change-height="(event) => setHeaderState('headerHeight', event)"
      @change-sticky="(event) => setHeaderState('isSticky', event)"
    >
      <template #icons>
        <CustomerHeaderIcon class="lg:hidden" />
      </template>
      <template #notifications>
        <AppSystemNotifications />
      </template>
      <template #headerTopLinks>
        <UiHeaderTopLinks
          class="hidden text-white lg:flex"
          :class="{ 'h-8': header?.isSticky, 'h-10': !header?.isSticky }"
        >
          <template #left>
            <button @click.prevent="toggleAppRegions">
              {{ t('region') }}
            </button>
          </template>

          <template #right>
            <ClientOnly v-if="props.initialData?.loginData">
              <span
                v-if="isLoggedIn && customerInfo"
                class="cursor-pointer"
                @click.prevent="toggleAppAccount"
              >
                {{ customerInfo?.fullname }}
              </span>
              <span
                v-if="!isLoggedIn"
                class="cursor-pointer"
                @click.prevent="toggleAppAccount()"
              >
                {{ t('log_in') }}
              </span>
              <span
                v-else
                class="cursor-pointer"
                @click.prevent="logOutCustomer({ userInitializedAction: true })"
              >
                {{ t('log_out') }}
              </span>
            </ClientOnly>
          </template>
        </UiHeaderTopLinks>
      </template>
      <template #region>
        <UiIcon
          name="globe"
          class="text-white"
          @click.prevent="toggleAppRegions"
        />
      </template>
    </UiCheckoutPageHeader>
  </DelayHydration>
</template>

<script lang="ts" setup>
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import type { AppHeader } from '@base/components/AppHeader/AppHeader.types'
import { useUiState } from '@base/composables/useUiState'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import CustomerHeaderIcon from '@customer/components/CustomerHeaderIcon/CustomerHeaderIcon.vue'

const props = defineProps<AppHeaderProps>()
const { localePath } = useT3Utils()
const { toggleAppRegions, toggleAppAccount } = useDrawer()
const { t } = useI18n()
const { isLoggedIn, logOutCustomer, customerData } = useCustomer()

const customerInfo = computed(() => customerData.value?.customer ?? null)

interface AppHeaderProps {
  initialData: T3InitialData
}
/**
 * Set header state (heigth and isSticky)
 */
const { component } = useUiState()
const header = component<AppHeader>('header')
function setHeaderState(type: keyof AppHeader, value: AppHeader) {
  header.value[type] = value
}
</script>
